/**
 * Third-party libraries
 */
import { Dropdown, Popover, Skeleton, Slider } from "antd";
import { useEffect, useRef, useState } from "react";

/**
 * Project components
 */
import "@/components/client/call/call-recording-player-card.css";
import { useCommunicationLogContext } from "@/components/client/communication-log";
import { CallRecordingStatus } from "@/components/client/graphql";
import { Icon } from "@/components/client/icon";
import { TimeUtility } from "@/components/common/time";

/**
 * Call Recording Player Card Props.
 */
type CallRecordingPlayerCardProps = {
  /**
   * Indicates that the call information card is visible.
   */
  visible?: boolean;
  /**
   * Indicates that audio recording is being loaded. Shows a skeleton when true.
   *
   * @defaultValue false
   */
  loading?: boolean;
};

/**
 * A card with an audio player to play the currently selected call recording.
 */
export function CallRecordingPlayerCard(props: CallRecordingPlayerCardProps) {
  const {
    selectedCommunicationLog
  } = useCommunicationLogContext();

  /**
   * The audio source to pass into <audio src={} />
   *
   * It falls back to the third-party URL if the synced URL (recordingUrl) is not available.
   *
   * `thirdPartyUrl` is only available for 1 year. `recordingUrl` is available with us forever.
   */
  const audioSource = selectedCommunicationLog?.recording?.synced ? selectedCommunicationLog?.recording?.recordingUrl : selectedCommunicationLog?.recording?.thirdPartyUrl;

  /** If there are any errors in loading the audio. */
  const [audioLoadError, setAudioLoadError] = useState<string>();
  useEffect(() => {
    // Reset audioLoadError whenever the audioSource (currently viewed call) changes.
    setAudioLoadError(undefined);
  }, [audioSource]);

  // Hide if not visible or no audio source.
  if (!props.visible) {
    return null;
  }
  return <div className="flex animate-slide-left flex-col items-start justify-start self-stretch rounded-md border border-slate-200 bg-white" data-sentry-component="CallRecordingPlayerCard" data-sentry-source-file="call-recording-player-card.tsx">
      <div className="inline-flex items-center justify-between self-stretch border-b border-slate-200 p-4" style={{
      borderBottom: "1px solid rgba(0,0,0,0.1)"
    }}>
        <div className="text-tpl-navy">
          Recording
          {selectedCommunicationLog?.recording && !selectedCommunicationLog?.recording?.synced ? "*" : ""}
        </div>
      </div>

      <div className="flex flex-col items-start justify-start gap-4 self-stretch p-4">
        {
      // 1. Loading
      props.loading ? <AudioPlayerSkeleton /> :
      // 2. Processing (IN_PROGRESS status, we don't need to indicate the others)
      selectedCommunicationLog?.recording?.status === CallRecordingStatus.InProgress ? <div className="text-sm text-tpl-navy">
              Recording is being processed...
            </div> :
      // 3. Error
      audioLoadError ? <div className="text-sm text-tpl-navy">{audioLoadError}</div> :
      // 4. Success
      audioSource ? <AudioPlayer audioSource={audioSource} onError={event => {
        if (event.currentTarget.error?.code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) {
          setAudioLoadError("Unable to load audio file. Invalid.");
        }
      }} /> :
      // 5. No recording
      <div className="text-sm text-tpl-navy">No call recording</div>}
      </div>
    </div>;
}

// ===========================================================================
// Subcomponents
// ===========================================================================

import React from "react";

/** Type for the AudioPlayer component. */
type AudioPlayerProps = {
  /** The audio source. */
  audioSource: string;
  /** Handler when an error is thrown upon loading. */
  onError?: (event: React.SyntheticEvent<HTMLAudioElement, Event>) => void;
};

/** A custom audio player component. */
function AudioPlayer({
  audioSource,
  onError
}: AudioPlayerProps) {
  // ===========================================================================
  // States
  // ===========================================================================

  /** True if the audio player is playing. */
  const [isPlaying, setIsPlaying] = useState(false);

  /** The volume of the audio player. */
  const [audioVolume, setAudioVolume] = useState(0.5);

  /** The current time of the audio player (in seconds). */
  const [audioCurrentTime, setAudioCurrentTime] = useState(0);

  /** The maximum time of the audio player (in seconds). */
  const [audioDuration, setAudioDuration] = useState(0);

  /** Reference to the <audio> element. */
  const audioRef = useRef<HTMLAudioElement>(null);

  // ===========================================================================
  // Variables
  // ===========================================================================

  /**
   * Helper function for formatting the `audioDuration` and `audioCurrentTime`
   * in "numeric" format.
   *
   * Will either be 00:00:00 or 00:00 based on `audioDuration`.
   * - When `audioDuration`'s hours is 0, ALL will be 00:00.
   * - When `audioDuration`'s hours > 0, ALL will be 00:00:00.
   */
  function formatDuration(duration: number) {
    /** True when audioDuration has hours. */
    const hasHours = Math.floor(audioDuration / 3600) > 0;
    return TimeUtility.formatDuration({
      duration: duration * 1000,
      // Convert to milliseconds.
      display: {
        hours: hasHours // When there are hours, display it. When not, hide it.
      }
    });
  }
  return <>
      {/* REAL AUDIO PLAYER (Hidden) */}
      <audio ref={audioRef} src={audioSource}
    /**
     * Comment `hidden` to debug the real audio player.
     */ hidden controls
    /**
     * Set duration when available. (Volume doesn't seem to be present, but still try to set it.)
     */ onLoadedMetadata={() => {
      setAudioDuration(audioRef.current?.duration ?? 0);
      setAudioVolume(audioRef?.current ? audioRef?.current?.volume * 100 : 100);
    }} onError={onError} onVolumeChange={() => {
      if (!audioRef.current) return;
      setAudioVolume(audioRef.current.volume * 100);
    }}
    /**
     * Only used when <audio> is not hidden:
     * When the user clicks audio slider.
     */ onTimeUpdate={() => {
      setAudioCurrentTime(audioRef.current?.currentTime ?? 0);
    }} onEnded={() => {
      /**
       * By default, audio will pause when ended (unless loop is true).
       */
      if (!audioRef.current?.loop) setIsPlaying(false);
    }} />

      {/* CUSTOM AUDIO PLAYER */}
      <div className="bg-neutral-light-gray flex w-full items-center gap-x-2 rounded-md">
        <button onClick={() => {
        if (!audioRef.current) return;
        if (isPlaying) {
          audioRef.current.pause();
          setIsPlaying(false);
        } else {
          audioRef.current.play();
          setIsPlaying(true);
        }
      }}>
          <Icon src={isPlaying ? "pause" : "play-arrow"} className="text-lg" data-sentry-element="Icon" data-sentry-source-file="call-recording-player-card.tsx" />
        </button>
        <div className="text-sm">
          {formatDuration(audioCurrentTime)}
          <span>{" / "}</span>
          {formatDuration(audioDuration)}
        </div>

        <div className="relative flex flex-grow items-center">
          <Slider className="call-recording-player w-full" classNames={{
          track: "!bg-semantic-red",
          rail: "!bg-[##E8EBF1]"
        }} max={audioDuration} onChange={value => {
          if (!audioRef.current) return;
          audioRef.current.currentTime = value;
        }} value={audioCurrentTime} tooltip={{
          formatter: value => formatDuration(value!)
        }} data-sentry-element="Slider" data-sentry-source-file="call-recording-player-card.tsx" />
        </div>

        <Popover
      // Idk how to adjust the width and padding. Tried rootClassName.
      trigger={["click"]} content={<div className="h-24">
              <Slider className="call-recording-player" vertical value={audioVolume} onChange={value => {
          if (!audioRef.current) return;
          audioRef.current.volume = value / 100;
          setAudioVolume(value);
        }} min={0} max={100} tooltip={{
          formatter: value => `${value}%`
        }} />
            </div>} placement="top" data-sentry-element="Popover" data-sentry-source-file="call-recording-player-card.tsx">
          <button>
            <Icon src={audioVolume > 50 ? "volume-high" : audioVolume === 0 ? "volume-mute" : "volume-low"} className="text-lg" data-sentry-element="Icon" data-sentry-source-file="call-recording-player-card.tsx" />
          </button>
        </Popover>
        <Dropdown trigger={["click"]} menu={{
        items: [{
          key: "1",
          label: "Download MP3",
          onClick: () => {
            if (!audioRef.current) return;

            /**
             * NOTE: `link.download = "filename.mp3"` doesn't work for cross-origin requests.
             * So make sure to add a `ResponseContentDisposition` header when
             * creating the signed url instead: https://macarthur.me/posts/trigger-cross-origin-download/
             */
            const link = document.createElement("a");
            link.href = audioRef.current.src;
            link.click();
          }
        }]
      }} data-sentry-element="Dropdown" data-sentry-source-file="call-recording-player-card.tsx">
          <button>
            <Icon src="more-horizontal" className="text-lg" data-sentry-element="Icon" data-sentry-source-file="call-recording-player-card.tsx" />
          </button>
        </Dropdown>
      </div>
    </>;
}
export function AudioPlayerSkeleton() {
  return <div className="relative flex w-full items-center" data-sentry-component="AudioPlayerSkeleton" data-sentry-source-file="call-recording-player-card.tsx">
      <div className="absolute left-4">
        <Icon src="play-arrow" className="text-lg !text-neutral-400" data-sentry-element="Icon" data-sentry-source-file="call-recording-player-card.tsx" />
      </div>

      <Skeleton.Input size="small" active block className="min-w-0 opacity-80" style={{
      height: 65,
      width: "100%"
    }} data-sentry-element="unknown" data-sentry-source-file="call-recording-player-card.tsx" />
    </div>;
}