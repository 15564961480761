/**
 * Third-party libraries.
 */
import { Skeleton } from "antd";
import { useMemo } from "react";

/**
 * Project components.
 */
import { CommunicationLogRouting } from "@/components/client/communication-log/types";
import { CallRoutingStatus } from "@/components/client/graphql";
import { Icon } from "@/components/client/icon";
import { DateUtility } from "@/components/common/utilities";

// =============================================================================
// Call Routing Skeleton
// =============================================================================

/**
 * Call Routing Card Skeleton Props.
 */
type CallRoutingCardSkeletonProps = {
  /**
   * Indicates that the call routing card skeleton is visible.
   */
  visible?: boolean;
};

/**
 * A loading skeleton for the call routing card item.
 */
function CallRoutingCardSkeleton({
  visible
}: CallRoutingCardSkeletonProps) {
  return <div className={`flex h-full w-full flex-col gap-4 overflow-y-auto ${visible ? "" : "hidden"}`} data-sentry-component="CallRoutingCardSkeleton" data-sentry-source-file="call-routing-card.tsx">
      <div className="flex w-full items-center justify-start gap-1">
        <Skeleton.Avatar size="default" active data-sentry-element="unknown" data-sentry-source-file="call-routing-card.tsx" />
        <div className="flex w-full flex-col gap-2">
          <Skeleton.Input size="small" active block data-sentry-element="unknown" data-sentry-source-file="call-routing-card.tsx" />
          <Skeleton.Input size="small" active block data-sentry-element="unknown" data-sentry-source-file="call-routing-card.tsx" />
        </div>
      </div>
    </div>;
}

// =============================================================================
// Call Routing Item
// =============================================================================

/**
 * Call Routing Item Props.
 */
type CallRoutingItemProps = {
  /**
   * Data to be displayed in the call routing item.
   */
  data: CommunicationLogRouting;
};

/**
 * An entry in the call routing card which denotes 1 routing history.
 *
 * Includes the following details:
 * - Name of the agent the call was routed to.
 * - Routing status.
 * - Routing date.
 */
function CallRoutingItem({
  data
}: CallRoutingItemProps) {
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================
  /**
   * The colors to display based on the status of the communication.
   * This includes the following:
   * - Background color of the card
   * - Icon color
   * - Text color
   */
  const iconColor = useMemo(() => {
    switch (data.status) {
      case CallRoutingStatus.Accepted:
        return "text-semantic-green";
      case CallRoutingStatus.Assigned:
      case CallRoutingStatus.Dialed:
        return "text-tpl-navy";
      case CallRoutingStatus.Missed:
      case CallRoutingStatus.Rejected:
        return "text-semantic-red";
      default:
        throw new Error(`Unknown status: ${data.status}`);
    }
  }, [data.status]);

  /**
   * The display status of the routing.
   *
   * This is done to convert American English to British English.
   */
  const displayStatus = useMemo(() => {
    switch (data.status) {
      case CallRoutingStatus.Dialed:
        return "DIALLED";
      default:
        return data.status;
    }
  }, [data.status]);
  return <div key={data.id} className="flex justify-between gap-1" data-sentry-component="CallRoutingItem" data-sentry-source-file="call-routing-card.tsx">
      <div className="flex items-start gap-1">
        <Icon src="phone" className={`!${iconColor} text-base! mt-1`} data-sentry-element="Icon" data-sentry-source-file="call-routing-card.tsx" />
        <div className="">
          <div className="text-sm font-semibold text-tpl-navy">
            {data.agentName}
          </div>
          <div className="text-sm text-tpl-navy-light">
            {displayStatus}
            {data.remark && ` - ${data.remark}`}
          </div>
        </div>
      </div>
      <div className="text-right text-sm text-tpl-navy lg:text-nowrap">
        {DateUtility.getTime({
        date: data.date
      })}
      </div>
    </div>;
}

// =============================================================================
// Call Routing Items
// =============================================================================

/**
 * Call Routing Items Props.
 */
type CallRoutingItemsProps = {
  /**
   * The data to display in the call routing items.
   */
  data?: CallRoutingItemProps["data"][];
  /**
   * Indicates that the call routing items are loading.
   * Displays a skeleton in place of the items.
   */
  loading?: boolean;
};

/**
 * Display multiple call routing items in a card.
 */
function CallRoutingItems({
  data,
  loading = true
}: CallRoutingItemsProps) {
  /**
   * All the call routings that are not an assignment to an agent.
   * We don't need to display assignment routes.
   */
  const filteredRoutings = useMemo(() => data?.filter(routing => routing.status !== CallRoutingStatus.Assigned), [data]);
  return <div className="flex max-h-56 w-full flex-col gap-4 overflow-y-auto p-4" data-sentry-component="CallRoutingItems" data-sentry-source-file="call-routing-card.tsx">
      <CallRoutingCardSkeleton visible={loading} data-sentry-element="CallRoutingCardSkeleton" data-sentry-source-file="call-routing-card.tsx" />
      {!loading && !filteredRoutings?.length && <div className="text-sm text-tpl-navy">No Available Agents</div>}
      {!loading && filteredRoutings?.map(routing => <CallRoutingItem key={routing.id} data={routing} />)}
    </div>;
}

// =============================================================================
// Call Routing Card
// =============================================================================

/**
 * Call Routing Card Props.
 */
export type CallRoutingCardProps = {
  /**
   * Indicates that the call routing records are being loaded.
   *
   * Shows a skeleton in place of the records.
   */
  loading?: boolean;
  /**
   * The routing history of the call.
   */
  routings: CallRoutingItemProps["data"][];
  /**
   * Indicates that the call routing card is visible.
   */
  visible?: boolean;
};

/**
 * A small card that lists down the routing history of a call.
 */
export function CallRoutingCard({
  loading,
  routings,
  visible
}: CallRoutingCardProps) {
  // ===========================================================================
  // ===========================================================================
  // Contexts
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Queries
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  if (!visible) {
    return null;
  }
  return <div className={`w-full animate-slide-left rounded-md border border-neutral-mid-grey bg-white`} data-sentry-component="CallRoutingCard" data-sentry-source-file="call-routing-card.tsx">
      <div className="flex flex-col items-start justify-start gap-4 self-stretch border-b border-neutral-mid-grey px-4 py-3" style={{
      borderBottom: "1px solid rgba(0, 0, 0, 0.1)"
    }}>
        <div className="text-sm font-semibold text-tpl-navy">
          Routing History
        </div>
      </div>
      <CallRoutingItems data={routings} loading={loading} data-sentry-element="CallRoutingItems" data-sentry-source-file="call-routing-card.tsx" />
    </div>;
}