/**
 * Third-party libraries.
 */
import { HTMLAttributes } from "react";

/**
 * Project components.
 */
import { ButtonCall, ButtonCallType } from "@/components/client/button";
import { CommunicationDirection, CommunicationLogStatus, useCommunicationLogContext } from "@/components/client/communication-log";
import { CommunicationLog } from "@/components/client/communication-log/types";
import { Icon } from "@/components/client/icon";
import { PlayWrightTestId } from "@/tests/constants";

/**
 * Call control ringing card properties.
 */
export type CallControlRingingCardProps = {
  /**
   * CSS classes for the card.
   */
  className?: HTMLAttributes<HTMLDivElement>["className"];
  /**
   * The selected communication log.
   */
  data: CommunicationLog | null;
};

/**
 * Displays a card with the ringing call details, an accept and reject button.
 */
export function CallControlRingingCard({
  className = "",
  data
}: CallControlRingingCardProps) {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const {
    accept,
    hangUp,
    isAccepting,
    isHangingUp
  } = useCommunicationLogContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const accepting = data?.id ? isAccepting({
    callId: data.id
  }) : false;
  const hangingUp = data?.id ? isHangingUp({
    callId: data.id
  }) : false;

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  if (!data || data.status !== CommunicationLogStatus.RINGING) {
    return null;
  }
  return <div className={`inline-flex w-full animate-slide-left flex-col items-center justify-center gap-4 bg-white p-12 ${className}`} data-sentry-component="CallControlRingingCard" data-sentry-source-file="call-control-ringing-card.tsx">
      <div className="flex h-40 flex-col items-center justify-center gap-2 self-stretch">
        <div className="text-sm font-semibold text-tpl-navy" data-testid={PlayWrightTestId.CallControlRingingCard.CALL_DIRECTION}>
          {data?.direction === CommunicationDirection.INBOUND ? "Incoming" : "Outgoing"}{" "}
          Call
        </div>
        <div className="relative flex h-16 w-16 items-center justify-center rounded-full bg-slate-100">
          <Icon src="person" data-sentry-element="Icon" data-sentry-source-file="call-control-ringing-card.tsx" />
        </div>
        {data.clientName && <div className="flex flex-col items-start self-stretch overflow-ellipsis">
            <div className="leading-17.5 self-stretch text-center text-sm font-semibold text-tpl-navy">
              {data.clientName}
            </div>
          </div>}
        <div className="leading-17.5 self-stretch text-center text-sm text-gray-400">
          {data?.direction === CommunicationDirection.INBOUND ? data?.from : data?.to}
        </div>
      </div>
      <div className="inline-flex items-start gap-2">
        {data?.direction === CommunicationDirection.INBOUND && <ButtonCall data-testid={PlayWrightTestId.CallControlRingingCard.ACCEPT_BUTTON} disabled={accepting || hangingUp} loading={accepting} icon={<Icon className="!text-white" src="phone" />} onClick={async () => {
        if (accepting || hangingUp) {
          return;
        }
        await accept({
          callId: data.id
        });
      }} type={ButtonCallType.ACCEPT} />}
        <ButtonCall data-testid={PlayWrightTestId.CallControlRingingCard.HANG_UP_BUTTON} disabled={accepting || hangingUp} loading={hangingUp} icon={<Icon className="!text-white" src="phone-down" />} onClick={async () => {
        if (accepting || hangingUp) {
          return;
        }
        await hangUp({
          callId: data.id,
          direction: data.call.direction,
          status: data.call.status
        });
      }} type={ButtonCallType.HANG_UP} data-sentry-element="ButtonCall" data-sentry-source-file="call-control-ringing-card.tsx" />
      </div>
    </div>;
}